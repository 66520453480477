/* You can add global styles to this file, and also import other style files */

// @use "@angular/material" as mat;
// @include mat.core();
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
// Syncfusion
@import "ej2-angular-diagrams/styles/material-dark.scss";
@import "ej2-angular-base/styles/material-dark.scss";
@import "ej2-angular-splitbuttons/styles/material-dark.scss";
@import "ej2-angular-navigations/styles/material-dark.scss";
@import "ej2-base/styles/material-dark.scss";
@import "ej2-buttons/styles/material-dark.scss";
@import "ej2-layouts/styles/material-dark.scss";
@import "ej2-angular-grids/styles/material-dark.scss";
@import "ej2-inputs/styles/material-dark.scss";
@import "ej2-popups/styles/material-dark.scss";
@import "ej2-angular-dropdowns/styles/material-dark.scss";
@import "ej2-splitbuttons/styles/material-dark.scss";
@import "ej2-navigations/styles/material-dark.scss";
@import "ej2-angular-popups/styles/material-dark.scss";
@import "ej2-angular-lists/styles/material-dark.scss";
@import "ej2-lists/styles/material-dark.scss";
@import "ej2-calendars/styles/material-dark.scss";
@import "ej2-angular-calendars/styles/material-dark.scss";
@import "ej2-dropdowns/styles/material-dark.scss";
@import "ej2-icons/styles/material.scss";
@import "ej2-angular-notifications/styles/material-dark.scss";
@import "ej2-angular-layouts/styles/material-dark.scss";
@import "ej2-grids/styles/material-dark.scss";
@import "ej2-angular-filemanager/styles/material-dark.scss";
@import "ej2-treegrid/styles/material-dark.scss";
@import "ej2-angular-inputs/styles/material-dark.scss";

html,
body {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 5px;
  font-family: "Roboto", "Helvetica Neue", "sans-serif";
}

.cdi-flex-container {
  display: flex;
  flex-direction: column;
}
.cdi-flex-header {
  order: 1;
}
.cdi-flex-main-content {
  display: flex;
  flex-direction: row;
}
.cdi-flex-footer {
  background-color: black;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border-top: 1px solid #616161;
}
.cdi-dark-mode {
  background-color: black;
  color: white !important;
}
.cdi-light-mode {
  background-color: white;
  color: black;
}
h1,
h2,
h3,
h4,
p {
  color: #fff;
  font-family: sans-serif !important;
}
.e-card-title {
  background-color: #212121;
  color: #fff;
}

.e-btn {
  text-transform: none !important;
}

ul {
  list-style-type: none;
}

.form-error {
  color: #ff6652;
}
.sendButton {
  float: right;
}
.e-danger {
  float: right;
  margin-right: 10px;
}
.e-grid td.e-selectionbackground {
  background-color: #303030;
}

input.e-input:-webkit-autofill,
input.e-input:-webkit-autofill:hover,
input.e-input:-webkit-autofill:focus,
input.e-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #424242 inset !important;
  -webkit-text-fill-color: #fff !important;
}
